const initialstate = []

function tab4Inputs(state = initialstate, action) {
    switch (action.type) {
        case "set_tab4Inputs":
            return  action.payload 
        case "clear_set_tab4Inputs":
            return initialstate

        default:
            return state
    }
}

export default tab4Inputs;