const initialstate = [];
function interview_recordings(state = initialstate, action) {
    switch (action.type) {
        case "set_interview_recordings":
            return  action.payload 
        case "reset_interview_recordings":
            return initialstate
        default:
            return state
    }
}

export default interview_recordings;