const initialstate = {
    code_editor_text: "",
    runtime_input: "",
    language: "45"

}

function code_editor(state = initialstate, action) {
    switch (action.type) {
        case "set_code_editor":
            return { ...state, ...action.payload }
        case "reset_code_editor":
            return initialstate

        default:
            return state
    }
}

export default code_editor;