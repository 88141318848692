const initialstate = null
function interviewer_authentication(state = initialstate, action) {
    switch (action.type) {
        case "set_interviewer_authentication":
            return action.payload
        case "reset_interviewer_authentication":
            return initialstate
        default:
            return state
    }
}

export default interviewer_authentication;