const initialstate = {}

function check_status(state = initialstate, action) {
    switch (action.type) {
        case "set_check_status":
            return  action.payload 
        case "clear_check_status":
            return initialstate

        default:
            return state
    }
}

export default check_status;