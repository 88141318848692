const initialstate = ""

function uuid(state = initialstate, action) {
    switch (action.type) {
        case "set_uuid":
            return  action.payload 
        case "clear_uuid":
            return initialstate

        default:
            return state
    }
}

export default uuid;