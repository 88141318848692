const initialstate = false

function meeting_over(state = initialstate, action) {
    switch (action.type) {
        case "set_meeting_over":
            return  action.payload 
        case "clear_set_meeting_over":
            return initialstate

        default:
            return state
    }
}

export default meeting_over;