const initialstate = {
    final_remarks: "",
    code_text: "",
    attachments: [],
    interview_feedback: "",
    interview_feedback_points: "",
    overall_rating: "",
    rating_disabled: false
}

function tab5Inputs(state = initialstate, action) {
    switch (action.type) {
        case "set_tab5Inputs":
            return  action.payload 
        case "clear_set_tab5Inputs":
            return initialstate

        default:
            return state
    }
}

export default tab5Inputs;