const initialstate = {
    plan: {
        aadhaar: true,
        pan: false,
    },
    mode: {
        email: false,
        mobile: true,
    }
}

function candidate_verification(state = initialstate, action) {
    switch (action.type) {
        case "set_candidate_verification":
            return { ...state, ...action.payload }
        case "reset_candidate_verification":
            return initialstate

        default:
            return state
    }
}

export default candidate_verification;