const initialstate = {
    resume: "",
    job_description : "",
    experience: "",
}

function candidate_header(state = initialstate, action) {
    switch (action.type) {
        case "set_candidate_header":
            return { ...state, ...action.payload }
        case "clear_set_candidate_header":
            return initialstate

        default:
            return state
    }
}

export default candidate_header;