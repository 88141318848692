import { combineReducers } from 'redux';
import uuid from "./interview_ids/uuid";
import aboutInterview from "./interview_ids/aboutInterview";
import interview_feedback from './interview-feedback';
import tab1Inputs from './tab1';
import tab2Inputs from './tab2';
import tab3Inputs from './tab3';
import tab4Inputs from './tab4';
import tab5Inputs from './tab5';
import candidate_interview_feedback from './interview-feedback/candidate';
import candidate_header from "./header"
import ended_interview_details from './ended/ended-interview-details';
import ended_interview_tab1 from './ended/ended-interview-details/tab1';
import ended_interview_tab2 from './ended/ended-interview-details/tab2';
import ended_interview_tab3 from './ended/ended-interview-details/tab3';
import ended_interview_tab4 from './ended/ended-interview-details/tab4';
import ended_interview_tab5 from './ended/ended-interview-details/tab5';
import check_status from './get-room/check-status';
import default_screenshots from './screenshots/default-screenshots';
import screenshots from './screenshots';
import meeting_start from './meeting/meeting-start';
import meeting_over from './meeting/meeting-over';
import code_editor from './code-editor';
import candidate_verification from './candidate/verification';
import candidate_verification_completed from './candidate/verification/completion';
import candidate_feedback from './candidate/feedback';
import interviewer_authentication from './interviewer/authentication';
import interview_recordings from './recording';
import route_name from './route-name';


export default combineReducers({
    uuid: uuid,
    route_name:route_name,
    code_editor:code_editor,
    check_status: check_status,
    aboutInterview: aboutInterview,
    meeting_start: meeting_start,
    meeting_over: meeting_over,
    screenshots: screenshots,
    default_screenshots: default_screenshots,
    tab1Inputs: tab1Inputs,
    tab2Inputs: tab2Inputs,
    tab3Inputs: tab3Inputs,
    tab4Inputs: tab4Inputs,
    tab5Inputs: tab5Inputs,
    interview_feedback: interview_feedback,
    candidate_interview_feedback: candidate_interview_feedback,
    candidate_header: candidate_header,
    ended_interview_details: ended_interview_details,
    ended_interview_tab1: ended_interview_tab1,
    ended_interview_tab2: ended_interview_tab2,
    ended_interview_tab3: ended_interview_tab3,
    ended_interview_tab4: ended_interview_tab4,
    ended_interview_tab5: ended_interview_tab5,
    candidate_verification: candidate_verification,
    candidate_verification_completed: candidate_verification_completed,
    candidate_feedback:candidate_feedback,
    interviewer_authentication:interviewer_authentication,
    interview_recordings: interview_recordings

})
