const initialstate = {
    interview_cordination: "",
    interview_platform: "",
    platform_feedback: "",
    errorDetails: ""
}

function interview_feedback(state = initialstate, action) {
    switch (action.type) {
        case "set_interview_feedback":
            return { ...state, ...action.payload }
        case "reset_interview_feedback":
            return initialstate

        default:
            return state
    }
}

export default interview_feedback;