const initialstate = {
    interviewer_rating: "",
    overall_interview_rating: "",
    platform_feedback: "",
    errorDetails: ""
}

function candidate_interview_feedback(state = initialstate, action) {
    switch (action.type) {
        case "set_candidate_interview_feedback":
            return { ...state, ...action.payload }
        case "clear_set_candidate_interview_feedback":
            return initialstate

        default:
            return state
    }
}

export default candidate_interview_feedback;