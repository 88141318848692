import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import store from "./store/index"
import { Provider } from 'react-redux';
import Loader from './components/loader';
const App = React.lazy(() => import("./App.js"));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Provider store={store}>
      <React.Suspense fallback={<Loader/>}>
        <App />
      </React.Suspense>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
