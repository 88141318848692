import "../css/App.css"
import "../css/room.css"
const Loader = () => (
    <div class="fullpage-loader d-flex">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
)
export default Loader