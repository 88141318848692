const initialstate = "";
function route_name(state = initialstate, action) {
    switch (action.type) {
        case "set_route_name":
            return  action.payload 
        case "reset_route_name":
            return initialstate
        default:
            return state
    }
}

export default route_name;