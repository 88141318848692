const initialstate = {
}

function ended_interview_details(state = initialstate, action) {
    switch (action.type) {
        case "set_ended_interview_details":
            return action.payload
        case "clear_set_ended_interview_details":
            return initialstate

        default:
            return state
    }
}

export default ended_interview_details;