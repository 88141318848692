const initialstate = [{
    skill_name: "",
    skill_error: "Please enter skills to give rating",
    rating_disabled: true,
    skills_rating: "",
    skill_comment: "",
    badge_points: ""
}]

function tab1Inputs(state = initialstate, action) {
    switch (action.type) {
        case "set_tab1Inputs":
            return  action.payload 
        case "clear_set_tab1Inputs":
            return initialstate

        default:
            return state
    }
}

export default tab1Inputs;