const initialstate = [
  {
    other_skill_name: "Approach & Attitude",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Confidence Level",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Communication Skills",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  }

]

const type_2_state = [
  {
    other_skill_name: "Confidence",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Communication Skills",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Pressure handling",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Approach & Attitude",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Problem solving",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Result oriented",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Decision making",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Team management",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  },
  {
    other_skill_name: "Accountability",
    skill_error: "",
    rating_disabled: false,
    other_ratings: "",
    other_ratings_comment: ""
  }
]


function tab3Inputs(state = initialstate, action) {
  switch (action.type) {
    case "set_tab3Inputs":
      return action.payload
    case "set_tab3Inputs_type_2": {
      if (action.payload) {
        const usersMap = new Map();
        action.payload.forEach(user => usersMap.set(user.other_skill_name, user));
        type_2_state.forEach(user => {
          const exists = usersMap.has(user.other_skill_name);
          if (!exists) {
            usersMap.set(user.other_skill_name, user);
          }
        })
        let otherRatingFilled = []
        usersMap.forEach(user => {
          otherRatingFilled.push(user)
        })
        return otherRatingFilled;
      }
      else return type_2_state
    }
    case "clear_set_tab3Inputs":
      return initialstate

    default:
      return state
  }
}

export default tab3Inputs;