const initialstate = {
}

function candidate_feedback(state = initialstate, action) {
    switch (action.type) {
        case "set_candidate_feedback":
            return action.payload 
        case "reset_candidate_feedback":
            return initialstate

        default:
            return state
    }
}

export default candidate_feedback;