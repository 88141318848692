const initialstate = null

function default_screenshots(state = initialstate, action) {
    switch (action.type) {
        case "set_default_screenshots":
            return  action.payload 
        case "reset_default_screenshots":
            return initialstate

        default:
            return state
    }
}
export default default_screenshots;