const initialstate = []

function ended_interview_tab3(state = initialstate, action) {
    switch (action.type) {
        case "set_ended_interview_tab3":
            return  action.payload 
        case "clear_ended_interview_tab3":
            return initialstate

        default:
            return state
    }
}

export default ended_interview_tab3;