const initialstate = false

function candidate_verification_completed(state = initialstate, action) {
    switch (action.type) {
        case "set_candidate_verification_completed":
            return action.payload
        case "reset_candidate_verification_completed":
            return initialstate

        default:
            return state
    }
}

export default candidate_verification_completed;